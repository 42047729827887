import * as i from 'types';

import LightningIcon from 'vectors/icons/lightning.svg';
import DieselIcon from 'vectors/icons/diesel.svg';

export const carEmissionsRows = (
  allCosts: i.AllCostsType,
  period: i.Period,
  carComparison: keyof i.CarInput | keyof i.VanInput,
  answers: i.Answers,
): i.ResultsCarTableRowType[] => {
  const carEmissionsData: i.ResultsCarTableRowType[] = [
    {
      title: {
        text: 'CO2 uitstoot per kilometer',
        variant: 'black',
        weight: 400,
      },
      items: [
        {
          icon: DieselIcon,
          text: `${allCosts.allCarsEmissions?.emissions[carComparison]?.petrol?.km} gram`,
        },
        {
          icon: LightningIcon,
          text: `${allCosts.allCarsEmissions?.emissions[carComparison]?.ev?.km} gram`,
        },
      ],
    },
    {
      title: {
        text: 'Duur contract auto',
      },
      items: [
        {
          icon: DieselIcon,
          text: `${answers.depreciation} jaar`,
        },
        {
          icon: LightningIcon,
          text: `${answers.depreciation} jaar`,
        },
      ],
    },
    {
      title: {
        text: 'Gemiddeld aantal km per jaar',
      },
      items: [
        {
          icon: DieselIcon,
          text: `${answers.range?.[carComparison]}`,
        },
        {
          icon: LightningIcon,
          text: `${answers.range?.[carComparison]}`,
        },
      ],
    },
    {
      title: {
        text: 'Totaal per jaar',
        weight: 700,
      },
      items: [
        {
          icon: DieselIcon,
          text: `${Math.round(
            Number(
              allCosts?.allCarsEmissions?.emissions[carComparison]?.petrol?.[
                period
              ],
            ),
          )} kg CO2`,
          weight: 700,
        },
        {
          icon: LightningIcon,
          text: `${Math.round(
            Number(
              allCosts?.allCarsEmissions?.emissions[carComparison]?.ev?.[
                period
              ],
            ),
          )} kg CO2`,
          variant: 'orange',
          weight: 700,
        },
      ],
    },
  ];

  return carEmissionsData;
};
