import styled from 'styled-components';

import { media } from 'styles/utils';

export const ResultSummaryCardContainer = styled.div`
  height: fit-content;
  width: 100%;
  padding: 24px 24px 32px;
  border: 1px solid ${({ theme }) => theme.colors.sand['100']};
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.ui.shadows};

  ${media.tablet`
    width: 100%;
    max-width: 456px;
  `}
`;

export const ResultSummaryCardTitle = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.sand['100']};
  display: flex;
  padding-bottom: 16px;
  margin-bottom: 32px;
  flex-direction: column;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray['800']};
`;
