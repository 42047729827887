import { Heading } from 'common/typography';
import styled from 'styled-components';

import { media } from 'styles/utils';

export const ResultCtaContainer = styled.section`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.sand['100']};
  border-radius: 4px;
  width: 100%;
  margin-bottom: 76px;

  ${media.tablet`
    flex-direction: row;
    align-items: center;
    max-width: 920px;
    height: 280px;
    margin: 40px auto 120px;
  `}
`;

export const ResultCtaTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 64px 16px 0px;
  text-wrap: balance;

  ${media.tablet`
    width: 60%;
    padding: 64px 0px 64px 64px;
  `}
`;

export const ResultCtaTitle = styled(Heading)`
  margin: 0;
`;

export const ResultCtaImageContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;

  ${media.tablet`
    width: 40%;
  `};

  img {
    height: 280px;
    object-fit: cover;
  }
`;
