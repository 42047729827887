import styled from 'styled-components';

import { Button } from 'common/interaction';
import { media } from 'styles/utils';

export const ResultTableExpandedFooterContainer = styled.div<ResultTableExpandedFooterContainerProps>`
  margin: 16px 0 0;
  display: ${({ isDesktopOnly }) => (isDesktopOnly ? 'none' : 'block')};

  ${media.desktop`
    margin: 16px 32px 0;
    display: block;
  `}
`;

type ResultTableExpandedFooterContainerProps = {
  isDesktopOnly?: boolean;
};

export const ResultTableExpandedFooterRedirect = styled(Button)`
  color: ${({ theme }) => theme.colors.orange['600']};
  background-color: transparent;
  padding: 16px 0;

  &:focus,
  &:hover {
    background-color: transparent;
  }

  @media (hover: hover) {
    &:hover {
      svg {
        transform: translateX(8px);
      }
    }
  }

  svg {
    width: 14px;
    height: 21px;
    margin-left: 8px;
    transition: transform 0.15s ease;
    fill: ${({ theme }) => theme.colors.orange['600']};
  }
`;

export const ResultTableExpandedFooterBottom = styled.div`
  display: flex;
  justify-content: center;
  border-top: 2px solid ${({ theme }) => theme.colors.gray.background};
  padding-top: 16px;
`;

export const ResultTableExpandedFooterCollapse = styled.button`
  appearance: none;
  border: 0;
  background-color: transparent;
  height: 32px;
  width: 32px;
  margin: 0;
  padding: 0;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.orange['600']};
  font-weight: 700;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
  transition: opacity 0.15s ease;

  @media (hover: hover) {
    &:hover {
      opacity: 0.8;
    }
  }

  & svg {
    fill: ${({ theme }) => theme.colors.orange['600']};
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }
`;
