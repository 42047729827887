import * as React from 'react';

import { ResultDisclaimerContainer } from './styled';

export const ResultDisclaimer = () => {
  return (
    <ResultDisclaimerContainer>
      De informatie en berekeningen zijn indicatief. Jouw individuele situatie
      kan anders zijn. Je kunt hier dan ook geen rechten aan ontlenen.
    </ResultDisclaimerContainer>
  );
};
