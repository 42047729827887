import { Label } from 'common/typography';
import styled from 'styled-components';

export const ResultSavingsHighlight = styled(Label)`
  height: 32px;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 4px;
  border: 1px solid ${({ theme }) => theme.colors.sand['100']};
  border-radius: 4px;
  margin-top: 8px;
  padding: 8px;
  font-weight: 400;

  & > svg {
    fill: ${({ theme }) => theme.colors.orange['600']};
    width: 16px;
    height: 16px;
  }
`;
