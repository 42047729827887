import * as React from 'react';

import { Heading } from 'common/typography';

import {
  ResultTableContainer,
  ResultTableHeader,
  ResultTableRow,
  ResultTableRowHeaderColumn,
  ResultTableTitle,
  ResultTableWrapper,
} from './styled';

const TableHeader: React.FC<ResultTableHeaderProps> = ({
  title,
  modal = null,
  children,
  alignTitle,
}) => {
  return (
    <ResultTableHeader>
      <ResultTableTitle $alignTitle={alignTitle}>
        <Heading as="h2" size={3} margin="0px" marginDesktop="0px">
          {title}
        </Heading>
        {modal}
      </ResultTableTitle>
      {children}
    </ResultTableHeader>
  );
};

type ResultTableHeaderProps = {
  alignTitle?: 'vertical' | 'horizontal';
  title: string;
  modal?: React.ReactElement;
};

const HeaderColumn: React.FC<ResultHeaderColumnProps> = ({
  children,
  fixedWidth,
  alignLeft,
  largeIcon,
}) => {
  return (
    <ResultTableRowHeaderColumn
      $fixedWidth={fixedWidth}
      $alignLeft={alignLeft}
      $largeIcon={largeIcon}
    >
      {children}
    </ResultTableRowHeaderColumn>
  );
};

type ResultHeaderColumnProps = {
  fixedWidth?: number;
  alignLeft?: boolean;
  largeIcon?: boolean;
};

const TableRow: React.FC<TableRowProps> = ({ children, isHeader }) => {
  return <ResultTableRow isHeader={isHeader}>{children}</ResultTableRow>;
};

type TableRowProps = {
  isHeader?: boolean;
};

const Table: React.FC = ({ children }) => {
  return <ResultTableContainer>{children}</ResultTableContainer>;
};

export default {
  container: Table,
  header: TableHeader,
  rowsWrapper: ResultTableWrapper,
  row: TableRow,
  headerColumn: HeaderColumn,
};
