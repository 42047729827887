import * as i from 'types';
import * as React from 'react';

import { useDevice } from 'hooks';
import { ResultCarsTableDesktop } from 'modules/results/ResultCarsTableDesktop';
import { ResultCarsTableMobile } from 'modules/results/ResultCarsTableMobile';

export const ResultCarsTableBusiness = ({
  period,
  setPeriod,
  answers,
  cars,
  costs,
  outcome,
  selectedEvFleet,
}: ResultCarsTableBusinessProps) => {
  const { isMobile, isTablet } = useDevice();

  return (
    <>
      {isMobile || isTablet ? (
        <ResultCarsTableMobile
          {...{
            answers,
            cars,
            costs,
            period,
            setPeriod,
            outcome,
            selectedEvFleet,
          }}
        />
      ) : (
        <ResultCarsTableDesktop
          {...{
            answers,
            cars,
            costs,
            period,
            setPeriod,
            outcome,
            selectedEvFleet,
          }}
        />
      )}
    </>
  );
};

type ResultCarsTableBusinessProps = {
  answers: i.Answers;
  cars: GatsbyTypes.ContentfulCars[];
  costs: i.AllCostsType;
  period: i.Period;
  setPeriod: i.SetState;
  outcome: string;
  selectedEvFleet: GatsbyTypes.ContentfulCars[];
};
