import * as React from 'react';

import { ModalTrigger } from 'common/interaction';
import { Paragraph } from 'common/typography';

const ResultModalCo2 = () => {
  return (
    <ModalTrigger
      size="large"
      variant="black"
      title="Directe CO2-uitstoot"
    >
      <Paragraph>
        In deze berekening wordt alleen de directe uitstoot vergeleken!
      </Paragraph>
      <Paragraph>
        Kijk je naar de door de fabriek opgegeven eigenschappen van elektrische
        auto's, dan zie je bij CO2-uitstoot 0 gram per kilometer staan. Er is
        dus geen directe uitstoot, maar daarentegen wel indirecte uitstoot:
        zowel in de productie van de auto als opwekking van elektriciteit zorgt
        voor uitstoot. Wel blijkt uit diverse studies dat de elektrische auto
        over de gehele levensduur schoner is dan een brandstofauto.
      </Paragraph>
    </ModalTrigger>
  );
};

export const ResultModalPaybackPeriod = () => {
  return (
    <ModalTrigger size="small" variant="black" title="Terugverdientijd">
      <Paragraph>
        De terugverdientijd is gebaseerd op de netto investering (aanschafkosten
        auto's/bussen, laadpalen en zonnepanelen minus subsidie en
        belastingvoordeel) die je doet. Mocht je de voorkeur voor operational of
        financial lease hebben aangegeven zit dit bedrag er niet in verwerkt.
      </Paragraph>
    </ModalTrigger>
  );
};

export default {
  Co2: ResultModalCo2,
  PaybackPeriod: ResultModalPaybackPeriod,
};

