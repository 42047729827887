import styled from 'styled-components';

import { Heading } from 'common/typography';
import { media } from 'styles/utils';
import LightningSvg from 'vectors/icons/lightning.svg';

export const ResultSummaryContainer = styled.section`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  gap: 80px;
  margin-top: ${({ theme }) => theme.sizes.headerHeight.mobile};
  padding: 72px 0 52px;

  ${media.tablet`
    margin-top: ${({ theme }) => theme.sizes.headerHeight.desktop};
  `}

  ${media.desktop`
    justify-content: space-between;
    padding: 156px 0px 52px;
    gap: 0px;
    flex-wrap: nowrap;
  `}
`;

export const ResultSummaryTitle = styled(Heading)`
  text-wrap: balance;
`;

export const HeadingButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  gap: 16px;

  ${media.tablet`
    flex-direction: row;
  `}

  button, a {
    display: flex;
    gap: 8px;
    width: 100%;

    ${media.tablet`
     width: auto;
     margin-bottom: 0;
  `}

    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

export const SummaryCardWrapper = styled.div`
  position: relative;
  height: fit-content;
  width: 100%;

  ${media.tablet`
    width: auto;
  `}
`;

export const SummaryCard = styled.div`
  width: 100%;
  padding: 48px 32px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 12px;
  height: fit-content;

  ${media.tablet`
    width: 552px;
  `}
`;

export const SummaryCardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${media.desktop`
    flex-direction: row;
  `}
`;

export const SummaryCardTitle = styled.div`
  border-bottom: 2px solid ${({ theme }) => theme.colors.gray.background};
  display: flex;
  margin-bottom: 28px;
  flex-direction: column;
`;

export const SummaryCardLightingIcon = styled(LightningSvg)`
  position: absolute;
  z-index: -1;
  opacity: 0.2;
  top: -149px;
  right: -245px;
  width: 406px;
  height: 506px;
  pointer-events: none;
`;
