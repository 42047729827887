import * as React from 'react';
import * as i from 'types';

import { ModalTrigger } from 'common/interaction';

import { carSelector } from 'services';
import ResultTable from '../ResultTable';
import { ResultCarsTableBusiness, ResultCarsTableConsumer } from './components';

export const ResultCarsTable = ({
  period,
  setPeriod,
  answers,
  cars,
  costs,
  outcome,
}: ResultCarsTableProps) => {
  const isBusinessFlow = answers.userType === 'business';

  // Loop over answers.fleet, find the associated car and push it to an array.
  const selectedEvFleet: GatsbyTypes.ContentfulCars[] = [];
  if (answers.fleet) {
    for (const fleetCarId of Object.keys(answers.fleet)) {
      const fleetCarEv = carSelector(
        cars,
        fleetCarId as i.KeyOfFleetInput,
        'ev',
        answers,
        true,
      );

      if (fleetCarEv) {
        selectedEvFleet.push(fleetCarEv);
      }
    }
  }

  // Sort the cars by their 'order' key on the results page.
  // We know that 'order' is there because cars in the TCO tool MUST have an order
  // and selectedEvFleet is an array of TCO tool cars.
  selectedEvFleet.sort((a, b) => a.order! - b.order!);

  return (
    <ResultTable.container>
      <ResultTable.header
        title="Berekening per automodel"
        modal={
          <ModalTrigger
            asText
            displayText="Wat wordt hier vergeleken?"
            text="In de berekening vergelijken we jouw gekozen elektrische voertuig met een verwant brandstof-model. Denk bijvoorbeeld aan een Volkswagen ID3 en een Volkswagen Golf, of een Peugeot E-Expert en een Peugeot Expert."
            title="Wat vergelijken we hier?"
          />
        }
      />
      {!isBusinessFlow && (
        <ResultCarsTableConsumer
          {...{
            answers,
            cars,
            costs,
            period,
            setPeriod,
            outcome,
            selectedEvFleet,
          }}
        />
      )}

      {isBusinessFlow && (
        <ResultCarsTableBusiness
          {...{
            answers,
            cars,
            costs,
            period,
            setPeriod,
            outcome,
            selectedEvFleet,
          }}
        />
      )}
    </ResultTable.container>
  );
};

type ResultCarsTableProps = {
  answers: i.Answers;
  cars: GatsbyTypes.ContentfulCars[];
  costs: i.AllCostsType;
  period: i.Period;
  setPeriod: i.SetState;
  outcome: string;
};
