import * as React from 'react';
import * as i from 'types';

import { Heading, Paragraph } from 'common/typography';

import {
  C02EquivalentContent,
  C02EquivalentList,
  C02EquivalentListItem,
} from './styled';

export const C02Equivalent = ({
  emissionDifference,
  answers,
}: C02EquivalentProps) => {
  const isConsumerFlow = answers?.userType === 'consumer';
  const numberOfConsumerCars =
    isConsumerFlow && answers.fleet ? Object.keys(answers.fleet).length : 0;
  const depreciationTime = !answers?.depreciation
    ? 5
    : parseInt(answers?.depreciation);
  const totalCO2SavingsinTons =
    (Math.round(emissionDifference) / 1000) * depreciationTime;
  const co2SavingsInTons = isConsumerFlow
    ? totalCO2SavingsinTons / numberOfConsumerCars
    : totalCO2SavingsinTons;
  const averagePerCategoryCopy =
    numberOfConsumerCars > 1 && 'gemiddeld per categorie';

  const equivalents = [
    {
      title: 'keer vliegen van Amsterdam naar Londen',
      multiplier: 16,
    },
    {
      title: 'jaar ademhalen',
      multiplier: 1.5,
    },
    {
      title: 'keer om de aarde fietsen op een elektrische fiets',
      multiplier: 3,
    },
  ];

  return (
    <C02EquivalentContent>
      <Heading as="h2" size={3} margin="0 0 28px 0">
        Je bespaart {averagePerCategoryCopy}{' '}
        {co2SavingsInTons.toFixed(1).replace('.', ',')} ton CO2 per{' '}
        {depreciationTime} jaar, dit staat gelijk aan:
      </Heading>
      <C02EquivalentList>
        {equivalents.map((item) => {
          const amount = Math.round(co2SavingsInTons * item.multiplier);

          return (
            <C02EquivalentListItem key={item.title}>
              <Heading margin="0">{amount}</Heading>
              <Paragraph noMargin align="center">
                {item.title}
              </Paragraph>
            </C02EquivalentListItem>
          );
        })}
      </C02EquivalentList>
    </C02EquivalentContent>
  );
};

type C02EquivalentProps = {
  customerSteps?: string;
  emissionDifference: number;
  answers: i.Answers;
};
