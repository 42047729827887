import { Heading } from 'common/typography';
import * as React from 'react';
import { carSelector } from 'services';
import * as i from 'types';
import { ExpandingCardRow } from '../ExpandingCardRow';
import { ResultCarsTableConsumerContainer } from '../ResultCarsTable/components/ResultCarsTableConsumer/styled';
import { totalCostsConsumer } from '../ResultRows';
import { ResultTotalConsumerContainer } from './styled';

export const ResultTotalConsumer = ({
  period,
  answers,
  cars,
  allCosts,
}: ResultTotalConsumerProps) => {
  const selectedEvFleet: GatsbyTypes.ContentfulCars[] = [];
  if (answers.fleet) {
    for (const fleetCarId of Object.keys(answers.fleet)) {
      const fleetCarEv = carSelector(
        cars,
        fleetCarId as i.KeyOfFleetInput,
        'ev',
        answers,
        true,
      );

      if (fleetCarEv) {
        selectedEvFleet.push(fleetCarEv);
      }
    }
  }
  selectedEvFleet.sort((a, b) => a.order! - b.order!);

  return (
    <ResultCarsTableConsumerContainer>
      {selectedEvFleet.map((evCar) => {
        const carComparison = evCar.comparison as i.KeyOfFleetInput;
        const costsRows = totalCostsConsumer(
          allCosts,
          carComparison,
          answers,
          period,
        );

        return (
          <ResultTotalConsumerContainer $isConsumerCarsTable key={evCar.id}>
            <Heading as="h2" size={4}>
              {evCar.description}
            </Heading>
            {costsRows?.map((row) => {
              return (
                <ExpandingCardRow
                  key={row.title.text}
                  title={row.title}
                  items={row.items}
                  subtitleSize={14}
                  subtitleWeight={100}
                  $isConsumerCarsTable
                  showTooltip
                />
              );
            })}
          </ResultTotalConsumerContainer>
        );
      })}
    </ResultCarsTableConsumerContainer>
  );
};

type ResultTotalConsumerProps = {
  answers: i.Answers;
  cars: GatsbyTypes.ContentfulCars[];
  allCosts: i.AllCostsType;
  period: i.Period;
};
