import * as React from 'react';

import { Label } from 'common/typography';

import { ResultTableRowTitleContainer } from './styled';

export const ResultTableRowTitle = ({
  title,
  subtitle,
  amountOfSelectedCar,
}: ResultTableRowTitleProps) => {
  if (!title) return null;

  return (
    <ResultTableRowTitleContainer>
      <Label size={1}>
        {title} {amountOfSelectedCar && ` (${amountOfSelectedCar}x)`}
      </Label>
      {subtitle && (
        <Label size={2} weight={400}>
          {subtitle}
        </Label>
      )}
    </ResultTableRowTitleContainer>
  );
};

type ResultTableRowTitleProps = {
  title?: string;
  subtitle?: string;
  amountOfSelectedCar?: number;
};
