import styled from 'styled-components';

import { Paragraph } from 'common/typography';
import { media } from 'styles/utils';
import ChevronSvg from 'vectors/icons/chevron.svg';
import DieselSvg from 'vectors/icons/diesel.svg';
import LightningSvg from 'vectors/icons/lightning.svg';

export const ProductThumbnail = styled.img`
  display: none;
  flex-shrink: 0;

  ${media.desktop`
    display: block;
    width: 86px;
    padding-right: 16px;
    height: 50px;
    object-fit: contain;
    justify-self: start;
  `}
`;

export const ProductColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  gap: 8px;
  width: 100%;
`;

export const HighlightText = styled(Paragraph)`
  margin: 0 10px 0 0;
`;

export const ChevronIcon = styled(ChevronSvg)<TCOStepToggle>`
  width: 24px;
  height: 24px;
  fill: ${({ theme }) => theme.colors.orange['600']};
  transform: ${({ isOpen }) => (isOpen ? 'rotate(0deg)' : 'rotate(180deg)')};
  position: absolute;
  top: 33%;
  border-radius: 8px;
  flex-shrink: 0;
`;

export const ExpandButton = styled.button<ExpandButtonProps>`
  background-color: ${({ theme }) => theme.colors.white};
  border: none;
  border-bottom: 2px solid ${({ theme }) => theme.colors.gray.background};
  padding: 24px 0;
  margin: 0 16px;
  width: calc(100% - 64px);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  height: initial;
  transition: height 0.3s ease;
  position: relative;

  &:last-of-type {
    border-bottom: ${({ isOpen, theme }) =>
      !isOpen
        ? '2px solid transparent'
        : `2px solid ${theme.colors.gray.background}`};
  }

  ${media.desktop`
    height: 96px;
    display: flex;
    flex-direction: row;
    padding: 16px 0;
    margin: 0 32px;
  `}

  & > svg {
    width: 32px;
    height: 32px;
    position: absolute;
    right: 24px;
    top: calc(50% - 12px);
    margin-left: 32px;
    transform: ${({ isOpen }) => (isOpen ? 'none' : 'scaleY(-1)')};
    transition: transform 0.15s ease;
    flex-shrink: 0;
    fill: ${({ theme }) => theme.colors.orange['600']};

    ${media.desktop`
      position: relative;
      right: 0;
      top: 0;
    `}
  }

  ${Paragraph} svg {
    width: 14px;
    height: 14px;
  }

  ${Paragraph} {
    line-height: 19px;
    margin: 0 0 6px 0;

    ${media.desktop`
      margin: 0;
    `}

    span {
      font-weight: 400;
      display: block;
    }
  }
`;

type ExpandButtonProps = {
  isOpen: boolean;
  disabled?: boolean;
};

type TCOStepToggle = {
  isOpen?: boolean;
};

export const DieselIcon = styled(DieselSvg)`
  margin-right: 15px;
`;

export const LightningIcon = styled(LightningSvg)`
  margin-right: 15px;
`;
