import * as React from 'react';
import * as i from 'types';

import { Highlight } from 'common/typography';
import { Tooltip } from 'common/interaction';

import { ProductThumbnail } from '../CollapsingTable/styled';
import {
  Row,
  RowItem,
  RowItemResult,
  RowItemResultText,
  RowItemResultTextContainer,
  RowItemsList,
  Title,
  TitleWrapper,
} from './styled';

export const ExpandingCardRow: React.FC<ExpandingCardRowProps> = ({
  title,
  items,
  noWrap,
  subtitleSize,
  subtitleWeight,
  type,
  image,
  $isConsumerCarsTable,
  showTooltip,
}) => {
  return (
    <Row {...{ noWrap, subtitleSize, subtitleWeight, $isConsumerCarsTable }}>
      {type === 'emissions' && image?.file && (
        <ProductThumbnail src={image?.file.url} alt={image?.title} />
      )}

      <div>
        <TitleWrapper $isConsumerCarsTable={$isConsumerCarsTable}>
          <Title
            variant={title.variant}
            weight={title.weight}
            align="left"
            $isConsumerCarsTable={$isConsumerCarsTable}
          >
            {title.text}{' '}
          </Title>
          {showTooltip && title.subtitle && <Tooltip title={title.subtitle} />}
        </TitleWrapper>
        <Title
          variant={title.variant}
          weight={title.weight}
          align="left"
          $alwaysShowSubtitle={title.alwaysShowSubtitle}
          $isConsumerCarsTable={$isConsumerCarsTable}
        >
          {type === 'emissions' && <span>CO2 uitstoot per jaar</span>}
          {title.subtitle && <span>{title.subtitle}</span>}
        </Title>
      </div>

      <RowItemsList>
        {items.map((item, index) => {
          const Icon = item.icon as React.ElementType;

          return (
            <RowItem
              key={index}
              noWrap={noWrap}
              weight={item.weight}
              variant={item.variant}
              hasHighlight={Boolean(item.highlight)}
              $isConsumerCarsTable={$isConsumerCarsTable}
            >
              <RowItemResult $isConsumerCarsTable={$isConsumerCarsTable}>
                <RowItemResultTextContainer>
                  {item.icon && <Icon />}
                  <RowItemResultText variant={item.variant}>
                    {item.text}
                  </RowItemResultText>
                </RowItemResultTextContainer>
                {item.highlight && <Highlight>{item.highlight}</Highlight>}
              </RowItemResult>
            </RowItem>
          );
        })}
      </RowItemsList>
    </Row>
  );
};

type ExpandingCardRowProps = {
  title: i.TableRowType;
  subtitle?: string;
  items: i.TableRowType[];
  noWrap?: boolean;
  subtitleSize?: number;
  subtitleWeight?: number;
  type?: 'costs' | 'emissions';
  image?: GatsbyTypes.ContentfulAsset;
  $isConsumerCarsTable?: boolean;
  showTooltip?: boolean;
};
