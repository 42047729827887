import * as React from 'react';
import * as i from 'types';

import { ModalTrigger } from 'common/interaction';
import { useDevice } from 'hooks';
import {
  ResultInfraTableDesktop,
  ResultInfraTableMobile,
} from 'modules/results';

import ResultTable from '../ResultTable';

export const ResultInfraTable = ({
  answers,
  costs,
  infra,
}: i.ResultInfraTableProps) => {
  const { isMobile, isTablet } = useDevice();
  const modalText =
    answers.userType === 'consumer'
      ? 'Voor het opladen van een EV-auto heb je gemiddeld acht panelen nodig. Er zijn verschillende soorten zonnepanelen die kunnen worden aangeschaft. Gemiddeld zijn de kosten 600 euro per paneel.'
      : 'Voor het opladen van een EV-auto heb je gemiddeld acht panelen nodig. Er zijn verschillende soorten zonnepanelen die kunnen worden aangeschaft. Gemiddeld zijn de kosten 600 euro per paneel. Ook kun je profiteren van de ISDE-subsidie en de EIA- en KIA-regeling. Soms zijn er ook nog aanvullende subsidies van bijvoorbeeld de provincie waar je aanspraak op kunt maken. Voor 2023 is in totaal € 30 miljoen beschikbaar voor zonnepanelen en kleinschalige windturbines samen. De maximale subsidie voor zonnepanelen is € 125 per kW gezamenlijk piekvermogen.';

  const headerModal =
    answers.solarPanels === 'no-yes' ? (
      <ModalTrigger
        asText
        displayText="Wat wordt hier vergeleken?"
        text={modalText}
        title="Over laadpalen en zonnepanelen"
      />
    ) : undefined;

  const newInfra: i.ChargingStation[] = infra.map((item) => {
    return {
      ...item,
      amount: answers.chargingAmount?.[item.infraType],
      location:
        item.infraType === 'business'
          ? 'bedrijventerrein'
          : 'bij werknemer thuis',
      type: item.infraType,
    };
  });

  return (
    <ResultTable.container>
      <ResultTable.header title="Infrastructuur" modal={headerModal} />
      {isMobile || isTablet ? (
        <ResultInfraTableMobile {...{ infra: newInfra, costs, answers }} />
      ) : (
        <ResultInfraTableDesktop {...{ infra: newInfra, costs, answers }} />
      )}
    </ResultTable.container>
  );
};
