import * as React from 'react';
import { navigate } from 'gatsby';

import ArrowRightIcon from 'vectors/icons/bold-arrow-right.svg';
import ChevronIcon from 'vectors/icons/chevron.svg';

import {
  ResultTableExpandedFooterContainer,
  ResultTableExpandedFooterBottom,
  ResultTableExpandedFooterRedirect,
  ResultTableExpandedFooterCollapse,
} from './styled';

export const ResultTableExpandedFooter = ({
  redirect,
  onCollapseTable,
  isDesktopOnly,
}: ResultTableExpandedFooterProps) => {
  return (
    <ResultTableExpandedFooterContainer isDesktopOnly={isDesktopOnly}>
      {redirect && (
        <ResultTableExpandedFooterRedirect
          onClick={() => navigate(redirect.url)}
        >
          {redirect.text} <ArrowRightIcon />
        </ResultTableExpandedFooterRedirect>
      )}
      <ResultTableExpandedFooterBottom>
        <ResultTableExpandedFooterCollapse onClick={onCollapseTable}>
          Verberg informatie <ChevronIcon />
        </ResultTableExpandedFooterCollapse>
      </ResultTableExpandedFooterBottom>
    </ResultTableExpandedFooterContainer>
  );
};

type ResultTableExpandedFooterProps = {
  isDesktopOnly?: boolean;
  redirect?: {
    url: string;
    text: string;
  };
  onCollapseTable?: () => void;
};
