import * as React from 'react';
import * as i from 'types';

import { ResultInfraExpandedTable, ResultListItem } from 'modules/results';
import { formatPrice, getInfraLocation, solarPanels } from 'services';

export const ResultInfraTableMobile = ({
  infra,
  costs,
  answers,
}: i.ResultNewInfraTableProps) => {
  const chosenInfra = infra.filter((item) => item.amount !== 0);
  const isBusinessFlow = answers.userType === 'business';

  return (
    <>
      {chosenInfra.map((item, index) => {
        if (!item || item.amount === 0 || item.isInTcoTool === false)
          return null;
        const oneOffCosts = formatPrice(
          item.infraType === 'business'
            ? costs.infraIndiv.business - costs.taxInfra.business
            : costs.infraIndiv.employees - costs.taxInfra.employees,
        );

        return (
          <ResultListItem
            key={`${item.model}_${index}`}
            header={{
              title: `${item.brand} ${item.model}`,
              subtitle: `Aantal: ${item.amount} keer`,
            }}
            rows={[
              {
                title: 'Aanschaf eenmalig',
                value: oneOffCosts,
                highlight: {
                  text: getInfraLocation(item.infraType),
                },
              },
            ]}
            type="infrastructure"
            isBusinessFlow={isBusinessFlow}
            isDisabled={isBusinessFlow ? false : true}
          >
            <ResultInfraExpandedTable {...{ costs, infra: item }} />
          </ResultListItem>
        );
      })}
      {Boolean(
        answers.solarPanels === 'no-yes' && costs.solarPanels.oneOffCosts,
      ) && (
        <ResultListItem
          header={{
            title: solarPanels.brand,
            subtitle: `Aantal: ${costs.solarPanels.amount} keer`,
          }}
          rows={[
            {
              title: 'Aanschaf eenmalig',
              value: formatPrice(
                Number(costs.solarPanels.oneOffCosts) - costs.taxInfra.business,
              ),
              highlight: {
                text: getInfraLocation(solarPanels.infraType),
              },
            },
          ]}
          type="infrastructure"
          isBusinessFlow={isBusinessFlow}
          isDisabled={isBusinessFlow ? false : true}
        >
          <ResultInfraExpandedTable {...{ costs, infra: solarPanels }} />
        </ResultListItem>
      )}
    </>
  );
};
