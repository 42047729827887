import * as React from 'react';

import { Heading, Label } from 'common/typography';
import {
  ResultSavingsHighlight,
  ResultTableExpandedFooter,
} from 'modules/results';
import { useDevice } from 'services/hooks';
import ChevronIcon from 'vectors/icons/chevron.svg';
import {
  ResultListItemContainer,
  ResultListItemExpandedContent,
  ResultListItemHeader,
  ResultListItemRow,
} from '../ResultListItem/styled';
import { ResultCarCardConsumerRowContent, ResultCarCardImage } from './styled';

export const ResultCarCardConsumer: React.FC<ResultCarCardConsumerProps> = ({
  children,
  header,
  rows,
  image,
  isBusinessFlow = true,
  showHighlight,
}) => {
  const { isMobile } = useDevice();
  const [isOpen, setIsOpen] = React.useState(isMobile ? false : true);

  return (
    <ResultListItemContainer $isConsumerCarsTable>
      <ResultListItemHeader
        isOpen={isOpen}
        onClick={() => setIsOpen(!isOpen)}
        disabled={!isMobile}
      >
        <Heading as="h2" size={3} margin="0" marginDesktop="0">
          {header.title}
        </Heading>
        {isMobile && <ChevronIcon />}
      </ResultListItemHeader>
      {rows.map((row, index) => {
        return (
          <ResultListItemRow key={`${row.title}_${index}`}>
            {image && (
              <ResultCarCardImage src={image?.file?.url} alt={image.title} />
            )}

            <Heading
              as="h3"
              size={2}
              margin="0"
              marginDesktop="0"
              variant="orange"
            >
              {row.value}
            </Heading>
            <ResultCarCardConsumerRowContent>
              <Label size={1} weight={400}>
                {row.title}
              </Label>
              {showHighlight && isBusinessFlow && row.highlight && (
                <ResultSavingsHighlight>
                  {row.highlight.icon && row.highlight.icon}
                  {row.highlight?.text}
                </ResultSavingsHighlight>
              )}
            </ResultCarCardConsumerRowContent>
          </ResultListItemRow>
        );
      })}
      <ResultListItemExpandedContent isOpen={isOpen}>
        {children}
        {isMobile && (
          <ResultTableExpandedFooter onCollapseTable={() => setIsOpen(false)} />
        )}
      </ResultListItemExpandedContent>
    </ResultListItemContainer>
  );
};

type ResultCarCardConsumerProps = {
  header: {
    title?: string;
    subtitle?: string;
    amountOfSelectedCar?: number;
  };
  rows: {
    title: string;
    value?: string;
    highlight?: {
      icon?: React.ReactNode;
      text: string;
    };
  }[];
  type?: 'infrastructure' | 'car';
  image?: GatsbyTypes.Maybe<GatsbyTypes.ContentfulAsset>;
  isBusinessFlow?: boolean;
  isDisabled?: boolean;
  showHighlight? :boolean;
};
