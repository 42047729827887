import styled from 'styled-components';

import { Heading } from 'common/typography';
import { media } from 'styles/utils';

export const C02EquivalentContent = styled.div`
  width: 100%;
  padding-bottom: 80px;

  ${media.tablet`
     padding: 40px 0 80px;
  `}
`;

export const C02EquivalentList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;

  ${media.tablet`
    flex-direction: row;
    gap: 24px;
  `}
`;

export const C02EquivalentListItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 141px;
  padding: 36px 52px;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  box-shadow: 0px 4px 12px 0px rgba(65, 65, 65, 0.15);
  scroll-snap-align: center;

  ${Heading} {
    margin-bottom: 12px;
    text-align: center;
    font-weight: 600;
    font-size: 36px;
    line-height: 48px;
    color: ${({ theme }) => theme.colors.orange['600']};
  }

  ${media.tablet`
    flex: 1 1 0;
    width: 0;
  `}
`;
