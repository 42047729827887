import styled from 'styled-components';
import { media } from 'styles/utils';

export const ResultCarsTableConsumerContainer = styled.div`
  ${media.desktop`
    display: flex;
    gap: 32px;

    div {
      max-width: 352px;
    }
  `}
`;
