import styled, { css } from 'styled-components';

import { Row } from 'modules/results/ExpandingCardRow/styled';
import { media } from 'styles/utils';
import { ResultTableRow } from '../ResultTable/styled';

export const ResultListItemContainer = styled.div<ResultListItemContainerProps>`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.ui.shadows};
  padding: 0 16px 32px;
  margin-bottom: 24px;

  ${({ $isConsumerCarsTable }) =>
    !$isConsumerCarsTable &&
    css`
      ${media.desktop`
         &:last-of-type {
           margin: 0;
         }
      `}
    `}
`;

type ResultListItemContainerProps = {
  $isConsumerCarsTable?: boolean;
};

export const ResultListItemHeader = styled.button<ResultListItemHeaderProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  height: 92px;
  background-color: transparent;
  width: 100%;
  border: 0;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  padding: 0;
  outline: none;

  & > svg {
    width: 32px;
    height: 32px;
    fill: ${({ theme }) => theme.colors.orange['600']};
    transition: transform 0.15s ease;
    transform: ${({ isOpen }) => (isOpen ? 'rotate(0deg)' : 'rotate(180deg)')};
  }
`;

type ResultListItemHeaderProps = {
  isOpen?: boolean;
  disabled?: boolean;
};

export const ResultListItemRow = styled.div`
  border-top: 2px solid ${({ theme }) => theme.colors.sand['100']};
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ResultListItemRowContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  width: 100%;
  min-height: 32px;

  & > p {
    margin: 0;
  }
`;

export const ResultListItemExpandedContent = styled.div<ResultListItemExpandedContentProps>`
  height: ${({ isOpen }) => (isOpen ? 'auto' : '0')};
  max-height: ${({ isOpen }) => (isOpen ? '2000px' : '0')};
  overflow: hidden;

  &&&& ${ResultTableRow}, ${Row} {
    margin: 0;
  }
`;

type ResultListItemExpandedContentProps = {
  isOpen?: boolean;
};
