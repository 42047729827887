import * as i from 'types';
import * as React from 'react';

import ResultTable from '../ResultTable';
import { ResultFilterTabContainer, ResultFilterTabButton } from './styled';

export const ResultFilterTab = ({
  period,
  setPeriod,
}: ResultFilterTabProps) => {
  return (
    <ResultTable.container>
      <ResultFilterTabContainer>
        <ResultFilterTabButton
          onClick={() => setPeriod('year')}
          isActive={period === 'year' ? true : false}
        >
          Per jaar
        </ResultFilterTabButton>
        <ResultFilterTabButton
          onClick={() => setPeriod('month')}
          isActive={period === 'month' ? true : false}
        >
          Per maand
        </ResultFilterTabButton>
      </ResultFilterTabContainer>
    </ResultTable.container>
  );
};

type ResultFilterTabProps = {
  period: i.Period;
  setPeriod: i.SetState;
};
