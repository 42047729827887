import * as React from 'react';
import * as i from 'types';

import { carCostsRows, carEmissionsRows } from 'modules/results';
import { calcSavings, carSelector, formatPrice } from 'services';
import EuroIcon from 'vectors/icons/euro-orange.svg';

import { ResultCarsExpandedTable, ResultListItem } from '../';

export const ResultCarsTableMobile = ({
  period,
  answers,
  cars,
  costs,
  selectedEvFleet,
}: i.ResultCarsTableProps) => {
  return (
    <>
      {selectedEvFleet.map((evCar) => {
        const carComparison = evCar.comparison as i.KeyOfFleetInput;
        const amountOfSelectedCar =
          answers.fleet && answers.fleet[carComparison];

        const petrolCar = carSelector(cars, carComparison, 'petrol', answers);
        const timeCopy = period === 'year' ? 'per jaar' : 'per maand';
        const costsRows = carCostsRows(costs, timeCopy, carComparison, answers);
        const emissionsRows = carEmissionsRows(
          costs,
          period,
          carComparison,
          answers,
        );
        const savings = calcSavings(costs, carComparison);

        return (
          <ResultListItem
            key={carComparison}
            header={{
              title: evCar?.description,
              amountOfSelectedCar: amountOfSelectedCar,
            }}
            rows={[
              {
                title: 'Besparing kosten',
                value: `${formatPrice(savings.costsSavings)}`,
                highlight: {
                  icon: <EuroIcon />,
                  text: `${savings.costsSavingsPercentage}% minder kosten`,
                },
              },
            ]}
          >
            <ResultCarsExpandedTable
              {...{
                costsRows,
                emissionsRows,
                evCar,
                petrolCar,
              }}
            />
          </ResultListItem>
        );
      })}
    </>
  );
};
