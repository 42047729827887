import * as React from 'react';
import * as i from 'types';

import { useAccordion } from 'hooks';
import { InfraTable } from 'modules/results';
import { solarPanels } from 'services';

import { Label } from 'common/typography';
import ResultTable from '../ResultTable';

export const ResultInfraTableDesktop = ({
  answers,
  infra,
  costs,
}: i.ResultNewInfraTableProps) => {
  const { itemIsOpen, toggleItem } = useAccordion();
  const isBusinessFlow = answers.userType === 'business';

  return (
    <ResultTable.rowsWrapper>
      <ResultTable.row isHeader>
        <ResultTable.headerColumn fixedWidth={86} />
        <ResultTable.headerColumn alignLeft>
          <Label size={2}>Laadpalen / Zonnepanelen</Label>
        </ResultTable.headerColumn>
        <ResultTable.headerColumn>
          <Label size={2}>Aanschaf eenmalig</Label>
        </ResultTable.headerColumn>
        {isBusinessFlow && <ResultTable.headerColumn fixedWidth={64} />}
      </ResultTable.row>
      {answers.chargingAmount &&
        infra.map((charger, index) => {
          if (!charger || charger.amount === 0 || charger.isInTcoTool === false)
            return null;

          return (
            <InfraTable
              key={`${charger.model}_${index}`}
              {...{ costs, infra: charger }}
              itemIsOpen={itemIsOpen}
              toggleItem={toggleItem}
              isBusinessFlow={isBusinessFlow}
            />
          );
        })}
      {/* Only add solar panels to the result if the user has chosen:
       "I don't have solar panels yet, but I want them" */}
      {Boolean(
        answers.solarPanels === 'no-yes' && costs.solarPanels.oneOffCosts,
      ) && (
        <InfraTable
          costs={costs}
          infra={{
            ...solarPanels,
            amount: costs.solarPanels.amount,
            oneOffCosts: costs.solarPanels.oneOffCosts,
          }}
          itemIsOpen={itemIsOpen}
          toggleItem={toggleItem}
          isBusinessFlow={isBusinessFlow}
        />
      )}
    </ResultTable.rowsWrapper>
  );
};
