import * as i from 'types';
import * as React from 'react';

import {
  ExpandingCardRow,
  ResultExpandedTableContainer,
  infraRows,
} from 'modules/results';
import ResultTable from 'modules/results/ResultTable';

export const ResultInfraExpandedTable = ({
  isOpen,
  costs,
  infra,
}: ResultInfraExpandedTableProps) => {
  const rows = infraRows({
    allCosts: costs,
    infraType: infra.infraType,
    oneOffCosts: infra.oneOffCosts,
  });

  return (
    <ResultExpandedTableContainer
      isOpen={typeof isOpen === 'undefined' ? true : isOpen}
    >
      <ResultTable.rowsWrapper>
        {rows?.map((row) => {
          return (
            <ExpandingCardRow
              key={row.title.text}
              title={row.title}
              items={row.items}
              subtitleSize={14}
              subtitleWeight={100}
            />
          );
        })}
      </ResultTable.rowsWrapper>
    </ResultExpandedTableContainer>
  );
};

type ResultInfraExpandedTableProps = {
  isOpen?: boolean;
  costs: i.AllCostsType;
  infra: i.NewInfraType;
};
