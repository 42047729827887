import * as React from 'react';
import * as i from 'types';

import { Paragraph } from 'common/typography';
import { useDevice } from 'hooks';
import {
  carCostsRows,
  carEmissionsRows,
  ResultCarsExpandedTable,
  ResultSavingsHighlight,
  ResultTableRowTitle,
} from 'modules/results';
import { calcSavings, formatPrice } from 'services';
import EuroIcon from 'vectors/icons/euro-orange.svg';

import {
  ChevronIcon,
  ExpandButton,
  ProductColumn,
  ProductThumbnail,
} from '../../styled';

export const CarTable: React.FC<TCOTableProps> = ({
  answers,
  costs,
  toggleItem,
  itemIsOpen,
  timeCopy,
  carComparison,
  period,
  evCar,
  petrolCar,
  amountOfSelectedCar,
}) => {
  const { isMobile } = useDevice();
  const costsRows = carCostsRows(costs, timeCopy, carComparison, answers);
  const emissionsRows = carEmissionsRows(costs, period, carComparison, answers);
  const savings = calcSavings(costs, carComparison);

  return (
    <>
      <ExpandButton
        onClick={() => toggleItem(carComparison)}
        isOpen={itemIsOpen(carComparison)}
      >
        <ProductThumbnail src={evCar?.image?.file?.url} alt={evCar?.model} />
        <ResultTableRowTitle
          title={evCar?.description}
          amountOfSelectedCar={amountOfSelectedCar}
        />
        <ProductColumn>
          <Paragraph>{formatPrice(savings.costsSavings)}</Paragraph>
          {savings.costsSavingsPercentage > 0 && (
            <ResultSavingsHighlight size={3}>
              <EuroIcon /> {savings.costsSavingsPercentage}% minder kosten
            </ResultSavingsHighlight>
          )}
        </ProductColumn>
        {!isMobile && <ChevronIcon />}
      </ExpandButton>
      <ResultCarsExpandedTable
        {...{ costsRows, emissionsRows, evCar, petrolCar }}
        isOpen={itemIsOpen(carComparison)}
        toggleItem={toggleItem}
      />
    </>
  );
};

type TCOTableProps = {
  answers: i.Answers;
  costs: i.AllCostsType;
  carComparison: keyof i.CarInput | keyof i.VanInput;
  evCar?: GatsbyTypes.ContentfulCars;
  timeCopy: string;
  toggleItem: (item: string) => void;
  itemIsOpen: (item: string) => boolean;
  period: i.Period;
  petrolCar?: GatsbyTypes.ContentfulCars;
  amountOfSelectedCar?: number;
};
