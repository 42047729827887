import { Button } from 'common/interaction';
import styled from 'styled-components';
import { media } from 'styles/utils';

export const ResultUsefulBlogsList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 48px;

  ${media.desktop`
    flex-direction: row;
    flex-wrap: wrap;
    gap: 32px;
    padding-bottom: 80px;
  `}
`;

export const ResultUsefulBlogsListItem = styled.li`
  display: flex;
  flex-direction: column;
  padding: 40px 24px;
  border: 1px solid ${({ theme }) => theme.colors.sand['200']};
  border-radius: 8px;

  ${media.desktop`
    flex: 0 1 33.333%;
    max-width: 352px;
  `}
`;

export const ResultUsefulBlogsLink = styled(Button)`
  flex-direction: row-reverse;
  margin-top: auto;
  gap: 8px;
  padding: 0;
`;
