import * as i from 'types';

import { carSelector } from 'services';
import Diesel from 'vectors/icons/diesel.svg';
import Lightning from 'vectors/icons/lightning.svg';

export const totalEmissionsRows = ({
  allCosts,
  period,
  cars,
  answers,
}: TotalEmissionsRowsProps): i.ResultTableRowType[] => {
  if (!allCosts.allCarsEmissions) return [];

  let carRows: i.ResultTableRowType[] = [];

  if (answers.fleet) {
    carRows = Object.keys(answers.fleet).map((fleetKey) => {
      const carComparison = fleetKey as i.KeyOfFleetInput;
      const evCar = carSelector(cars, carComparison, 'ev', answers, true);
      const emissions = allCosts.allCarsEmissions?.emissions[carComparison];

      if (!evCar || !emissions) {
        return {
          title: {
            text: '',
          },
          items: [],
        };
      }

      return {
        order: evCar.order,
        title: {
          text:
            answers.userType === 'business'
              ? `${evCar.description} (${emissions?.chosenAmount}x)`
              : `${evCar.description}`,
          subtitle: `Op basis van ingevulde km: ${
            answers.range && answers.range[carComparison]
          }`,
          alwaysShowSubtitle: true,
          weight: 700,
          image: evCar.image,
        },
        items: [
          {
            text: `${Math.round(
              Number(emissions?.petrol?.[period]) * emissions?.chosenAmount,
            )} kg CO2`,
            icon: Diesel,
          },
          {
            text: `${Math.round(
              Number(emissions?.ev?.[period]) * emissions?.chosenAmount,
            )} kg CO2`,
            icon: Lightning,
          },
        ],
      };
    });
  } else {
    carRows = [];
  }

  return [...carRows];
};

type TotalEmissionsRowsProps = {
  allCosts: i.AllCostsType;
  period: i.Period;
  cars: GatsbyTypes.ContentfulCars[];
  answers: i.Answers;
};
