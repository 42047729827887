import * as i from 'types';

import { formatPrice, getTimeUnit } from 'services';
import Diesel from 'vectors/icons/diesel.svg';
import Lightning from 'vectors/icons/lightning.svg';

export const totalCostRows = (
  allCosts: i.AllCostsType,
  answers: i.Answers,
  period: i.Period,
): i.ResultTableRowType[] => {
  const timeUnit = getTimeUnit(period);

  return [
    {
      title: {
        text: `Netto investering (${timeUnit})`,
        variant: 'black',
        subtitle:
          'Aanschafkosten auto en infrastructuur minus subsidie en belastingvoordeel',
        weight: 700,
      },
      items: [
        {
          text: `${
            period === 'year'
              ? formatPrice(
                  allCosts.oneOff.petrolPrice / parseInt(answers.depreciation!),
                )
              : formatPrice(
                  allCosts.oneOff.petrolPrice /
                    parseInt(answers.depreciation!) /
                    12,
                )
          }`,
          highlight:
            allCosts.oneOff.petrolPrice === 0
              ? undefined
              : `In ${answers.depreciation} jaar ${formatPrice(
                  allCosts.oneOff.petrolPrice,
                )}`,
          variant: 'black',
          icon: Diesel,
        },
        {
          text:
            period === 'year'
              ? formatPrice(
                  allCosts.total.ev.oneOffMinusSubsidiesAndTaxes /
                    parseInt(answers.depreciation!),
                )
              : formatPrice(
                  allCosts.total.ev.oneOffMinusSubsidiesAndTaxes /
                    parseInt(answers.depreciation!) /
                    12,
                ),
          highlight: `In ${answers.depreciation} jaar ${formatPrice(
            allCosts.total.ev.oneOffMinusSubsidiesAndTaxes,
          )}`,
          variant: 'black',
          icon: Lightning,
        },
      ],
    },
    {
      title: {
        text: `Vaste kosten (${timeUnit})`,
        variant: 'black',
        subtitle:
          'Financial / operational lease, motorrijtuigenbelasting, verzekeringen en onderhoud',
        weight: 700,
      },
      items: [
        {
          text: `${formatPrice(allCosts.total.petrol.fixed)}`,
          variant: 'black',
          icon: Diesel,
        },
        {
          text: `${formatPrice(allCosts.total.ev.fixed)}`,
          variant: 'black',
          icon: Lightning,
        },
      ],
    },
    {
      title: {
        text: `Variabele kosten (${timeUnit})`,
        variant: 'black',
        subtitle: 'Laad- of benzinekosten',
        weight: 700,
      },
      items: [
        {
          text: `${formatPrice(allCosts.total.petrol.variable)}`,
          variant: 'black',
          icon: Diesel,
        },
        {
          text: `${formatPrice(allCosts.total.ev.variable)}`,
          variant: 'black',
          icon: Lightning,
        },
      ],
    },
    {
      title: {
        text: `Totale kosten ${timeUnit}`,
        variant: 'black',
        weight: 700,
      },
      items: [
        {
          text: `${formatPrice(allCosts.total.petrol.total)}`,
          variant: 'black',
          weight: 700,
          icon: Diesel,
        },
        {
          text: `${formatPrice(allCosts.total.ev.totalMinusSubsidiesAndTaxes)}`,
          variant: 'orange',
          weight: 700,
          icon: Lightning,
        },
      ],
    },
  ];
};
