import * as React from 'react';
import * as i from 'types';

import { useAccordion, useDevice } from 'hooks';
import { CarTable } from 'modules/results';
import { carSelector, getTimeUnit } from 'services';

import { Label } from 'common/typography';
import ResultTable from '../ResultTable';

export const ResultCarsTableDesktop = ({
  period,
  setPeriod,
  answers,
  cars,
  costs,
  outcome,
  selectedEvFleet,
}: i.ResultCarsTableProps) => {
  const { itemIsOpen, toggleItem } = useAccordion();
  const { isMobile } = useDevice();
  const timeCopy = getTimeUnit(period, isMobile);

  return (
    <ResultTable.rowsWrapper>
      <ResultTable.row isHeader>
        <ResultTable.headerColumn fixedWidth={86} />
        <ResultTable.headerColumn alignLeft>
          <Label size={2}>Voertuig(en)</Label>
        </ResultTable.headerColumn>
        {!isMobile && (
          <>
            <ResultTable.headerColumn>
              <Label size={2}>Kosten bespaard</Label>
            </ResultTable.headerColumn>
            <ResultTable.headerColumn fixedWidth={64} />
          </>
        )}
      </ResultTable.row>
      {selectedEvFleet.map((evCar) => {
        const petrolCar = carSelector(
          cars,
          evCar.comparison as i.KeyOfFleetInput,
          'petrol',
          answers,
        );
        const carComparison = evCar.comparison as i.KeyOfFleetInput;
        const amountOfSelectedCar =
          answers.fleet && answers.fleet[carComparison];

        return (
          <CarTable
            key={carComparison}
            {...{
              answers,
              cars,
              costs,
              setPeriod,
              period,
              outcome,
            }}
            evCar={evCar}
            petrolCar={petrolCar}
            carComparison={carComparison}
            itemIsOpen={itemIsOpen}
            toggleItem={toggleItem}
            timeCopy={timeCopy}
            amountOfSelectedCar={amountOfSelectedCar}
          />
        );
      })}
    </ResultTable.rowsWrapper>
  );
};
