import { Label } from 'common/typography';
import * as React from 'react';
import * as i from 'types';
import {
  ResultTotalCardContainer,
  ResultTotalCardResultsList,
  ResultTotalCardResultsListItem,
  ResultTotalCardTextContainer,
} from './styled';

export const ResultTotalCard: React.FC<ResultTotalCardProps> = ({
  title,
  items,
  noWrap,
  subtitleSize,
  subtitleWeight,
  type,
  image,
}) => {
  return (
    <ResultTotalCardContainer>
      <ResultTotalCardTextContainer>
        <Label>{title.text}</Label>
        {type === 'emissions' && (
          <Label weight={400}>CO2 uitstoot per jaar</Label>
        )}
        <Label size={2}>{title.subtitle && title.subtitle}</Label>
      </ResultTotalCardTextContainer>
      <ResultTotalCardResultsList>
        {items.map((item, index) => {
          const Icon = item.icon as React.ElementType;
          return (
            <ResultTotalCardResultsListItem key={index}>
              {item.icon && <Icon />}
              {item.text}
            </ResultTotalCardResultsListItem>
          );
        })}
      </ResultTotalCardResultsList>
    </ResultTotalCardContainer>
  );
};

type ResultTotalCardProps = {
  title: i.TableRowType;
  subtitle?: string;
  items: i.TableRowType[];
  noWrap?: boolean;
  subtitleSize?: number;
  subtitleWeight?: number;
  type?: 'costs' | 'emissions';
  image?: GatsbyTypes.ContentfulAsset;
};
