import * as i from 'types';
import * as React from 'react';

import { Highlight, Paragraph } from 'common/typography';
import {
  ExpandingCardRow,
  ResultExpandedTableContainer,
  ResultTableExpandedFooter,
  ResultTableRowTitle,
  infraRows,
} from 'modules/results';
import { formatPrice, getInfraLocation } from 'services';
import ChevronIcon from 'vectors/icons/chevron.svg';

import { ExpandButton, ProductColumn, ProductThumbnail } from '../../styled';

export const InfraTable = ({
  costs,
  toggleItem,
  itemIsOpen,
  infra,
  isBusinessFlow,
}: TCOTableProps) => {
  const isSolarPanels = infra.id === 'solarPanels';
  const rows = infraRows({
    allCosts: costs,
    infraType: infra.infraType,
    oneOffCosts: infra.oneOffCosts,
    isSolarPanels,
  });
  const title = infra.model ? `${infra?.brand} ${infra.model}` : infra?.brand;

  // Solar panels have a fixed price and are not stored in Contentful therefore they overrule
  // the calculation for charging stations and other infrastructure items
  let totalCosts = 0;

  if (isSolarPanels && costs.solarPanels.oneOffCosts) {
    totalCosts =
      costs.solarPanels.oneOffCosts - costs.subsidyInfraIndiv.solarPanels;
  } else {
    if (infra.infraType === 'business') {
      totalCosts = costs.infraIndiv.business - costs.taxInfra.business;
    } else {
      totalCosts = costs.infraIndiv.employees - costs.taxInfra.employees;
    }
  }

  return (
    <>
      <ExpandButton
        onClick={() => toggleItem(infra.id)}
        isOpen={itemIsOpen(infra.id)}
        disabled={!isBusinessFlow}
      >
        <ProductThumbnail src={infra.image.file.url} alt={title} />
        <ResultTableRowTitle
          title={title}
          subtitle={`Aantal: ${infra.amount} keer`}
        />
        <ProductColumn>
          <Paragraph>{formatPrice(totalCosts)}</Paragraph>
          {isBusinessFlow && (
            <Highlight>{getInfraLocation(infra.infraType)}</Highlight>
          )}
        </ProductColumn>
        {isBusinessFlow && <ChevronIcon />}
      </ExpandButton>
      <ResultExpandedTableContainer isOpen={itemIsOpen(infra.id)}>
        {rows.map((row) => {
          return (
            <ExpandingCardRow
              key={row.title.text}
              title={row.title}
              subtitle={row.subtitle}
              items={row.items}
              noWrap
            />
          );
        })}
        <ResultTableExpandedFooter
          onCollapseTable={() => toggleItem(infra.id)}
        />
      </ResultExpandedTableContainer>
    </>
  );
};

type TCOTableProps = {
  costs: i.AllCostsType;
  infra: i.NewInfraType;
  toggleItem: (item: string) => void;
  itemIsOpen: (item: string) => boolean;
  isBusinessFlow?: boolean;
};
