import styled, { css } from 'styled-components';

import { media } from 'styles/utils';

import { ResultTableRow } from '../ResultTable/styled';

export const ResultExpandedTableContainer = styled.div<ResultExpandedTableContainerProps>`
  background-color: ${({ theme }) => theme.colors.white};
  overflow: hidden;
  margin: 0;
  padding: 0;
  max-height: ${({ isOpen }) => (isOpen ? '2000px' : '0px')};
  position: relative;

  ${ ResultTableRow } {
    padding-left: 0;
    padding-right: 0;
    margin: 0 32px;
  };

  ${({ $isConsumerCarsTable }) =>
    !$isConsumerCarsTable &&
    css`
      ${media.desktop<ResultExpandedTableContainerProps>`
        overflow: hidden;
        padding: ${({ isOpen }) => (isOpen ? '0 48px 48px' : '0 48px')};
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;

        &:not(:last-of-type) {
          &:after {
            content: '';
            display: block;
            height: 2px;
            background-color: ${({ theme }) => theme.colors.gray.background};
            width: calc(100% - 64px);
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            }
          }
       `}
  `}
`;

type ResultExpandedTableContainerProps = {
  isOpen?: boolean;
  $isConsumerCarsTable?: boolean;
};
