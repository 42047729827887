import styled, { css } from 'styled-components';

import { media } from 'styles/utils';

export const ResultTableContainer = styled.div`
  padding-bottom: 52px;

  ${media.desktop`
    padding-bottom: 80px;
  `}
`;

export const ResultTableHeader = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 32px;
`;

export const ResultTableTitle = styled.div<ResultTableTitleProps>`
  ${({ $alignTitle }) =>
    $alignTitle === 'horizontal' &&
    css`
      display: flex;
      gap: 8px;
      align-items: center;

      & > button {
        top: 0;
      }
    `}
`;

type ResultTableTitleProps = {
  $alignTitle?: 'vertical' | 'horizontal';
};

export const ResultTableRow = styled.div<ResultTableRowProps>`
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  margin: 0;
  padding: 20px 0;
  padding-top: 32px;
  border-bottom: ${({ isHeader, theme }) =>
    isHeader ? `2px solid ${theme.colors.gray.background}` : 0};

  &:first-of-type {
    border-top: 2px solid ${({ theme }) => theme.colors.gray.background};
  }

  ${media.desktop`
    margin: 0 32px;
    padding: 20px 0;
    align-items: center;

    &:first-of-type {
      border-top: 0;
    }
  `}
`;

type ResultTableRowProps = {
  isHeader?: boolean;
};

export const ResultTableWrapper = styled.div<ResultTableWrapperProps>`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  padding: 0;
  border: 1px solid
    ${({ theme, noBorder }) => (noBorder ? 'none' : theme.colors.sand['100'])};
  width: 100%;

  & > ${ResultTableRow} {
    &:first-of-type {
      padding-left: 16px;
      padding-right: 16px;

      ${media.desktop`
        padding-right: 0;
        padding-left: 0;
      `}
    }
  }
`;

type ResultTableWrapperProps = {
  noBorder?: boolean;
};

export const ResultTableRowHeaderColumn = styled.div<ResultTableRowHeaderColumnProps>`
  gap: 12px;
  align-items: center;
  justify-content: ${({ $alignLeft }) =>
    $alignLeft ? 'flex-start' : 'flex-end'};
  display: none;
  width: 100%;

  &:first-of-type {
    display: flex;
    margin-right: auto;
    justify-content: flex-start;
  }

  ${media.desktop`
    display: flex;
  `}

  svg {
    width: ${({ $largeIcon }) => ($largeIcon ? '24px' : '14px')};
    height: ${({ $largeIcon }) => ($largeIcon ? '24px' : '16px')};
  }

  & > span {
    display: none;

    ${media.desktop`
      display: inline;
    `}
  }

  ${({ $fixedWidth }) =>
    $fixedWidth &&
    css`
      width: ${$fixedWidth}px;
      flex-shrink: 0;

      &:first-of-type {
        width: ${$fixedWidth}px;
        min-width: 0;
      }
    `}
`;

type ResultTableRowHeaderColumnProps = {
  $alignLeft?: boolean;
  $fixedWidth?: number;
  $largeIcon?: boolean;
};
