import * as React from 'react';

import {
  ResultSummaryCardContainer,
  ResultSummaryCardTitle,
} from './styled';

export const ResultSummaryCard = ({
  title,
  children,
}: ResultSummaryCardProps) => {
  return (
    <ResultSummaryCardContainer>
      <ResultSummaryCardTitle>{title}</ResultSummaryCardTitle>
      {children}
    </ResultSummaryCardContainer>
  );
};

type ResultSummaryCardProps = {
  title: string;
  children: React.ReactNode;
};
