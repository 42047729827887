import * as React from 'react';
import * as i from 'types';

import { Paragraph } from 'common/typography';

import { Button } from 'common/interaction';
import {
  ResultCtaContainer,
  ResultCtaImageContainer,
  ResultCtaTextContainer,
  ResultCtaTitle,
} from './styled';

export const ResultCta = ({ image, answers }: ResultCtaProps) => {
  const isBusinessflow = answers.userType === 'business';

  return (
    <div style={{ margin: '0px' }}>
      <ResultCtaContainer>
        <ResultCtaTextContainer>
          <ResultCtaTitle as="h2" size={2} margin="0" marginDesktop="0">
            Meer weten over elektrisch rijden?
          </ResultCtaTitle>
          <Paragraph margin="16px 0 24px">
            Lees meer over duurzame mobiliteit, subsidies, nieuwe risico’s en
            meer.
          </Paragraph>
          <Button
            href={
              isBusinessflow
                ? 'https://www.nn.nl/Zakelijk/Duurzaamheid.htm'
                : 'https://www.nn.nl/Over-NationaleNederlanden/Duurzame-samenleving/Klimaat.htm'
            }
            target="blank"
          >
            Lees meer
          </Button>
        </ResultCtaTextContainer>
        <ResultCtaImageContainer>
          <img src={image} alt="Auto" loading="lazy" />
        </ResultCtaImageContainer>
      </ResultCtaContainer>
    </div>
  );
};

type ResultCtaProps = {
  image: string;
  answers: i.Answers;
};
