import * as React from 'react';

import { formatNegativePrice } from 'services';
import LabelIcon from 'vectors/icons/label.svg';

import {
  SummaryCardItemContainer,
  SummaryCardItemDifference,
  SummaryCardItemDifferenceLabel,
  SummaryCardItemDifferenceUnit,
  SummaryCardItemEmissionContainer,
  SummaryCardItemTitle,
} from './styled';

export const SummaryCardItem: React.FC<SummaryCardItemProps> = ({
  costsDifference,
  isEmissionsItem,
  savedEmissions,
  totalSavedEmissionsPercentage,
}) => {
  return (
    <SummaryCardItemContainer>
      <SummaryCardItemTitle size={2}>
        {isEmissionsItem
          ? 'Bespaar je directe CO2 uitstoot'
          : 'Bespaar je kosten'}
      </SummaryCardItemTitle>
      <SummaryCardItemDifference>
        {costsDifference ? (
          formatNegativePrice(costsDifference)
        ) : (
          <SummaryCardItemEmissionContainer>
            <div>
              {savedEmissions?.toFixed(0)}{' '}
              <SummaryCardItemDifferenceUnit>kg</SummaryCardItemDifferenceUnit>
            </div>
            <SummaryCardItemDifferenceLabel size={2}>
              {totalSavedEmissionsPercentage}% minder uitstoot
              <LabelIcon />
            </SummaryCardItemDifferenceLabel>
          </SummaryCardItemEmissionContainer>
        )}
      </SummaryCardItemDifference>
    </SummaryCardItemContainer>
  );
};

type SummaryCardItemProps = {
  costsDifference?: number;
  isEmissionsItem?: boolean;
  savedEmissions?: number;
  totalSavedEmissionsPercentage?: number;
};
