import * as React from 'react';

import { Heading, Paragraph } from 'common/typography';
import ArrowRight from 'vectors/icons/arrow-right.svg';
import {
  ResultUsefulBlogsLink,
  ResultUsefulBlogsList,
  ResultUsefulBlogsListItem,
} from './styled';

export const ResultUsefulBlogs = ({ data }: ResultUsefulBlogsProps) => {
  if (!data) return null;

  return (
    <>
      <Heading as="h2" size={3}>
        {data.title}
      </Heading>
      <ResultUsefulBlogsList>
        {data.items?.map((item) => (
          <ResultUsefulBlogsListItem key={item?.id}>
            <Heading
              as="h3"
              size={4}
              margin="0 0 16px"
              marginDesktop="0 0 16px"
            >
              {item?.title}
            </Heading>
            <Paragraph newSize={1} margin="0 0 40px">
              {item?.subtitle}
            </Paragraph>
            <ResultUsefulBlogsLink
              href={item?.url}
              target="_blank"
              icon={<ArrowRight />}
              variant="text"
            >
              Lees meer
            </ResultUsefulBlogsLink>
          </ResultUsefulBlogsListItem>
        ))}
      </ResultUsefulBlogsList>
    </>
  );
};

type ResultUsefulBlogsProps = {
  data: GatsbyTypes.ContentfulComponentBlog;
};
