import styled from 'styled-components';
import { media } from 'styles/utils';

export const ResultEmissionsSection = styled.section`
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  padding-top: 80px;
  background-color: ${({ theme }) => theme.colors.sand['100']};

  ${media.desktop`
    padding-top: 120px;
  `}

  & > * {
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr min(1120px, calc(100% - 32px)) 1fr;

    & > * {
      grid-column: 2; // Children of ResultEmissionsSection will have the 1120px max-width and 16px margin applied automatically.
    }
  }
`;
