import * as React from 'react';
import * as i from 'types';

import { useDevice } from 'hooks';
import {
  ExpandingCardRow,
  ResultDisclaimer,
  ResultTotalCard,
  ResultTotalConsumer,
  totalCostRows,
  totalEmissionsRows,
} from 'modules/results';
import Diesel from 'vectors/icons/diesel.svg';
import Lightning from 'vectors/icons/lightning.svg';

import { Label } from 'common/typography';
import ResultModal from '../ResultModal';
import ResultTable from '../ResultTable';
import { ResultEmissionsConsumerList } from './styled';

export const ResultTotalTable = ({
  allCosts,
  period,
  answers,
  type,
  cars,
}: ResultTotalTableProps) => {
  const { isMobile } = useDevice();
  const { userType } = answers;

  let rows = [];
  let title = '';
  let firstColumnTitle = '';

  if (type === 'costs') {
    title = 'Totale kosten';
    firstColumnTitle = 'Type kosten';
    rows = totalCostRows(allCosts, answers, period);
  } else {
    title = 'Totale directe CO2 uitstoot';
    firstColumnTitle = 'Voertuig(en)';
    rows = totalEmissionsRows({ allCosts, period, cars, answers });
  }

  // Sort the cars by their 'order' key on the results page.
  // We know that 'order' is there because cars in the TCO tool MUST have an order.
  rows.sort((a, b) => a.order! - b.order!);
  const modal = type === 'emissions' ? <ResultModal.Co2 /> : undefined;

  return (
    <ResultTable.container>
      <ResultTable.header title={title} modal={modal} alignTitle="horizontal" />

      {/* Total costs overview - consumer flow */}
      {userType === 'consumer' && type === 'costs' && (
        <ResultTotalConsumer
          {...{
            answers,
            cars,
            allCosts,
            period,
          }}
        />
      )}

      {/* Total costs overview - business flow */}
      {userType === 'business' && type === 'costs' && (
        <ResultTable.rowsWrapper>
          <ResultTable.row>
            <ResultTable.headerColumn alignLeft>
              <Label size={2}>{firstColumnTitle}</Label>
            </ResultTable.headerColumn>
            {!isMobile && (
              <>
                <ResultTable.headerColumn>
                  <Diesel /> <Label size={2}>Brandstof</Label>
                </ResultTable.headerColumn>
                <ResultTable.headerColumn>
                  <Lightning /> <Label size={2}>Elektrisch</Label>
                </ResultTable.headerColumn>
              </>
            )}
          </ResultTable.row>
          {rows.map((row) => {
            return (
              <ExpandingCardRow
                key={row.title.text}
                title={row.title}
                subtitle={row.subtitle}
                items={row.items}
                subtitleSize={16}
                subtitleWeight={400}
                type={type}
                image={row.title.image}
              />
            );
          })}
        </ResultTable.rowsWrapper>
      )}

      {type === 'costs' && <ResultDisclaimer />}

      {/* Total emissions overview - consumer flow */}
      {/* Total emissions overview - business flow - mobile */}
      {(userType === 'consumer' ||
        (isMobile && userType === 'business')) &&
        type === 'emissions' && (
          <ResultEmissionsConsumerList>
            {rows.map((row) => (
              <ResultTotalCard
                key={row.title.text}
                title={row.title}
                subtitle={row.subtitle}
                items={row.items}
                subtitleSize={16}
                subtitleWeight={400}
                type={type}
                image={row.title.image}
              />
            ))}
          </ResultEmissionsConsumerList>
        )}

      {/* Total emissions overview - business flow - desktop */}
      {!isMobile && userType === 'business' && type === 'emissions' && (
        <ResultTable.rowsWrapper>
          <ResultTable.row>
            <ResultTable.headerColumn fixedWidth={86} />
            <ResultTable.headerColumn alignLeft>
              <Label size={2}>{firstColumnTitle}</Label>
            </ResultTable.headerColumn>
            {!isMobile && (
              <>
                <ResultTable.headerColumn>
                  <Diesel /> <Label size={2}>Brandstof</Label>
                </ResultTable.headerColumn>
                <ResultTable.headerColumn>
                  <Lightning /> <Label size={2}>Elektrisch</Label>
                </ResultTable.headerColumn>
              </>
            )}
          </ResultTable.row>
          {rows.map((row) => {
            return (
              <ExpandingCardRow
                key={row.title.text}
                title={row.title}
                subtitle={row.subtitle}
                items={row.items}
                subtitleSize={16}
                subtitleWeight={400}
                type={type}
                image={row.title.image}
              />
            );
          })}
        </ResultTable.rowsWrapper>
      )}
    </ResultTable.container>
  );
};

type ResultTotalTableProps = {
  allCosts: i.AllCostsType;
  period: i.Period;
  answers: i.Answers;
  type: 'costs' | 'emissions';
  cars: GatsbyTypes.ContentfulCars[];
};
