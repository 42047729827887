import styled from 'styled-components';

export const ResultTotalCardContainer = styled.li`
  min-width: 352px;
  margin-bottom: 16px;
  padding: 24px 16px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
`;

export const ResultTotalCardTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

export const ResultTotalCardResultsList = styled.ul`
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  padding: 24px 0px;
  border-top: 1px solid ${({ theme }) => theme.colors.sand['100']};
`;

export const ResultTotalCardResultsListItem = styled.li`
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    height: 16px;
    width: 15px;
  }
`;

export const ResultTotalCardResultsItem = styled.div``;
