import * as React from 'react';
import * as i from 'types';

import { Label } from 'common/typography';
import {
  ExpandingCardRow,
  ResultExpandedTableContainer,
  ResultTableExpandedFooter,
} from 'modules/results';
import ResultTable from 'modules/results/ResultTable';
import DieselIcon from 'vectors/icons/diesel.svg';
import EuroIcon from 'vectors/icons/euro-orange.svg';
import LightningIcon from 'vectors/icons/lightning.svg';
import {
  ResultTableHeaderConsumer,
  ResultTableHeaderConsumerItem,
} from './styled';

export const ResultCarsExpandedTable = ({
  costsRows,
  isOpen,
  toggleItem,
  evCar,
  petrolCar,
  answers,
  $isConsumerCarsTable,
}: ResultCarsExpandedTableProps) => {
  const toggleIsOpen = () => {
    if (toggleItem && evCar?.model) {
      toggleItem(evCar?.model);
    }
  };

  return (
    <ResultExpandedTableContainer
      isOpen={typeof isOpen === 'undefined' ? true : isOpen}
      $isConsumerCarsTable={$isConsumerCarsTable}
    >
      <ResultTable.rowsWrapper noBorder>
        <div>
          <ResultTable.row>
            <ResultTable.headerColumn alignLeft largeIcon>
              {!$isConsumerCarsTable ? (
                <>
                  <EuroIcon /> Kosten
                </>
              ) : (
                <ResultTableHeaderConsumer>
                  <ResultTableHeaderConsumerItem>
                    <DieselIcon />{' '}
                    <Label size={2}>
                      {petrolCar?.brand} {petrolCar?.model}
                    </Label>
                  </ResultTableHeaderConsumerItem>
                  <ResultTableHeaderConsumerItem>
                    <LightningIcon />{' '}
                    <Label size={2}>
                      {evCar?.brand} {evCar?.model}
                    </Label>
                  </ResultTableHeaderConsumerItem>
                </ResultTableHeaderConsumer>
              )}
            </ResultTable.headerColumn>
            {!$isConsumerCarsTable && (
              <>
                <ResultTable.headerColumn>
                  <DieselIcon /> {petrolCar?.brand} {petrolCar?.model}
                </ResultTable.headerColumn>
                <ResultTable.headerColumn largeIcon>
                  <LightningIcon /> {evCar?.brand} {evCar?.model}
                </ResultTable.headerColumn>
              </>
            )}
          </ResultTable.row>
          {costsRows?.map((row) => {
            return (
              <ExpandingCardRow
                key={row.title.text}
                title={row.title}
                items={row.items}
                subtitleSize={14}
                subtitleWeight={100}
                $isConsumerCarsTable={$isConsumerCarsTable}
              />
            );
          })}
        </div>
        {/* is desktop only because the mobile version uses a different component where
        the footer needs to be rendered outside of this component.
        See /modules/results/ResultListItem/index.tsx */}
        {!$isConsumerCarsTable && (
          <ResultTableExpandedFooter
            isDesktopOnly
            onCollapseTable={toggleIsOpen}
          />
        )}
      </ResultTable.rowsWrapper>
    </ResultExpandedTableContainer>
  );
};

type ResultCarsExpandedTableProps = {
  costsRows: i.ResultsCarTableRowType[];
  emissionsRows: i.ResultsCarTableRowType[];
  isOpen?: boolean;
  setIsOpen?: () => void;
  evCar?: GatsbyTypes.ContentfulCars;
  petrolCar?: GatsbyTypes.ContentfulCars;
  toggleItem?: (item: string) => void;
  answers?: i.Answers;
  $isConsumerCarsTable?: boolean;
};
