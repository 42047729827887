import styled from 'styled-components';

import { Button } from 'common/interaction';

export const ResultFilterTabContainer = styled.div`
  position: relative;
  display: flex;

  &:after {
    position: absolute;
    content: '';
    top: 100%;
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.colors.sand['200']};
  }
`;

export const ResultFilterTabButton = styled(Button)<ResultFilterTabButtonProps>`
  font-size: 14px;
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.gray['1200'] : theme.colors.gray['800']};
  background-color: transparent;
  border-radius: 0;
  border-bottom: 1px solid
    ${({ theme, isActive }) =>
      isActive ? theme.colors.orange['600'] : 'transparent'};
  z-index: 1;

  :focus,
  :hover {
    background-color: transparent;
  }

  :active {
    outline: none;
  }
`;

type ResultFilterTabButtonProps = {
  isActive: boolean;
};
