import styled from 'styled-components';
import { ResultListItemRowContent } from '../ResultListItem/styled';

export const ResultCarCardImage = styled.img`
  margin: 24px auto;
  max-height: 132px;
`;

export const ResultCarCardConsumerRowContent = styled(ResultListItemRowContent)`
  align-items: flex-end;
`;
