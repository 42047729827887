import styled from 'styled-components';

export const ResultCarsExpandedTableDivider = styled.div`
  height: 80px;
  width: 100%;
`;

export const ResultTableHeaderConsumer = styled.div`
  display: flex;
  width: 100%;
  padding-inline: 16px;
  gap: 8px;
`;

export const ResultTableHeaderConsumerItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  gap: 8px;
  
  svg {
    height: 16px;
    width: 16px;
  }
`;
