import * as React from 'react';

import { Label, Paragraph } from 'common/typography';
import {
  ResultSavingsHighlight,
  ResultTableExpandedFooter,
  ResultTableRowTitle,
} from 'modules/results';
import ChevronIcon from 'vectors/icons/chevron.svg';

import {
  ResultListItemContainer,
  ResultListItemExpandedContent,
  ResultListItemHeader,
  ResultListItemRow,
  ResultListItemRowContent,
} from './styled';

export const ResultListItem: React.FC<ResultListItemProps> = ({
  children,
  header,
  rows,
  isBusinessFlow = true,
  isDisabled = false,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <ResultListItemContainer>
      <ResultListItemHeader isOpen={isOpen} onClick={() => setIsOpen(!isOpen)} disabled={isDisabled}>
        <ResultTableRowTitle
          title={header.title}
          subtitle={header.subtitle}
          amountOfSelectedCar={header.amountOfSelectedCar}
        />
        {!isDisabled && <ChevronIcon />}
      </ResultListItemHeader>
      {rows.map((row, index) => {
        return (
          <ResultListItemRow key={`${row.title}_${index}`}>
            <Label size={2}>{row.title}</Label>
            <ResultListItemRowContent>
              <Paragraph>{row.value}</Paragraph>
              {isBusinessFlow && row.highlight && (
                <ResultSavingsHighlight>
                  {row.highlight.icon && row.highlight.icon}
                  {row.highlight?.text}
                </ResultSavingsHighlight>
              )}
            </ResultListItemRowContent>
          </ResultListItemRow>
        );
      })}
      <ResultListItemExpandedContent isOpen={isOpen}>
        {children}
        <ResultTableExpandedFooter onCollapseTable={() => setIsOpen(false)} />
      </ResultListItemExpandedContent>
    </ResultListItemContainer>
  );
};

type ResultListItemProps = {
  header: {
    title?: string;
    subtitle?: string;
    amountOfSelectedCar?: number;
  };
  rows: {
    title: string;
    value?: string;
    highlight?: {
      icon?: React.ReactNode;
      text: string;
    };
  }[];
  type?: 'infrastructure' | 'car';
  isBusinessFlow?: boolean;
  isDisabled?: boolean;
};
