import styled from 'styled-components';

export const ResultTableRowTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  width: 100%;

  & > p {
    margin: 0;
  }
`;
