import styled from 'styled-components';

import { Label } from 'common/typography';
import { media } from 'styles/utils';

export const SummaryCardItemContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(3, auto);
  row-gap: 16px;
  width: 100%;

  ${media.desktop`
    width: 50%;
  `}
`;

export const SummaryCardItemTitle = styled(Label)`
  height: fit-content;
`;

export const SummaryCardItemDifference = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 0;
  line-height: 32px;
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  color: ${({ theme }) => theme.colors.orange['600']};
`;

export const SummaryCardItemDifferenceUnit = styled.span`
  padding-left: 8px;
  font-size: 14px;
  font-weight: 700;
  line-height: 26px;
  color: ${({ theme }) => theme.colors.gray['1200']};
`;

export const SummaryCardItemDifferenceLabel = styled(Label)`
  border: 1px solid ${({ theme }) => theme.colors.sand['100']};
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 400;
  margin: 0;
  width: fit-content;
  white-space: nowrap;
  padding: 4px 8px;

  svg {
    width: 16px;
    height: 16px;
  }

  & > button {
    top: 0;
  }

  ${media.desktop`
    width: 100%;
  `}
`;

export const SummaryCardItemEmissionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
