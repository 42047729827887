import * as i from 'types';

import { formatPrice } from 'services';

export const infraRows = ({
  allCosts,
  infraType,
  oneOffCosts,
  isSolarPanels,
}: InfraRowsType): RowType[] => {
  const taxBenefit: RowType = {
    title: {
      text: 'Netto belastingvoordeel MIA',
      variant: 'black',
      subtitle:
        'De MIA is voor alle ondernemers die onder de inkomstenbelasting of vennootschapsbelasting vallen. Echter wordt elke case persoonlijk behandelt, kijk op de RVO website voor meer informatie',
        weight: 700,
    },
    items: [
      {
        text: `- ${formatPrice(
          infraType === 'business'
            ? allCosts.taxInfra.business
            : allCosts.taxInfra.employees,
        )}`,
        variant: 'black',
      },
    ],
  };

  const solarPanelsSubsidies: RowType[] = [
    {
      title: {
        text: 'ISDE subsidie',
        variant: 'black',
        weight: 700,
      },
      subtitle: 'Alleen beschikbaar vanaf 40+ zonnepanelen',
      items: [
        {
          text: `- ${formatPrice(allCosts.subsidyInfraIndiv.solarPanels)}`,
          variant: 'black',
        },
      ],
    },
    {
      title: {
        text: 'EIA subsidie',
        variant: 'black',
        weight: 700,
      },
      items: [
        {
          text: '45,5% aftrekbaar van fiscale winst',
          variant: 'black',
        },
      ],
    },
    {
      title: {
        text: 'KIA regeling',
        variant: 'black',
        weight: 700,
      },
      items: [
        {
          text: '28% aftrekbaar van fiscale winst',
          variant: 'black',
        },
      ],
    },
  ];

  let totalOneOffCosts = 0;

  if (oneOffCosts) {
    totalOneOffCosts = oneOffCosts;
  } else {
    if (infraType === 'business') {
      totalOneOffCosts = allCosts.infraIndiv.business;
    } else {
      totalOneOffCosts = allCosts.infraIndiv.employees;
    }
  }

  let totalCosts = 0;

  if (isSolarPanels) {
    totalCosts = totalOneOffCosts - allCosts.subsidyInfraIndiv.solarPanels;
  } else if (infraType === 'business') {
    totalCosts = totalOneOffCosts - allCosts.taxInfra.business;
  } else {
    totalCosts = totalOneOffCosts - allCosts.taxInfra.employees;
  }

  const infraData: RowType[] = [
    {
      title: {
        text: 'Aanschaf',
        variant: 'black',
        weight: 700,
      },
      subtitle: '(eenmalig)',
      items: [
        {
          text: `Vanaf ${formatPrice(totalOneOffCosts)}`,
          variant: 'black',
        },
      ],
    },
    {
      title: {
        text: 'Totaal',
        variant: 'black',
        weight: 700,
      },
      items: [
        {
          text: `${formatPrice(totalCosts)}`,
          variant: 'orange',
          weight: 700,
        },
      ],
    },
  ];

  if (!isSolarPanels) {
    infraData.splice(1, 0, taxBenefit);
  }

  if (isSolarPanels && allCosts.subsidyInfraIndiv.solarPanels > 0) {
    infraData.splice(1, 0, ...solarPanelsSubsidies);
  }

  return infraData;
};

type RowType = {
  title: i.TableRowType;
  items: i.TableRowType[];
  subtitle?: string;
};

type InfraRowsType = {
  allCosts: i.AllCostsType;
  infraType: i.ChargingStation['infraType'];
  oneOffCosts?: number;
  isSolarPanels?: boolean;
};
