import styled, { css } from 'styled-components';
import { media } from 'styles/utils';

export const ResultTotalConsumerContainer = styled.div<ResultTotalConsumerContainerProps>`
  margin-bottom: 24px;
  padding: 24px 16px 32px;
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.ui.shadows};

  ${({ $isConsumerCarsTable }) =>
    !$isConsumerCarsTable &&
    css`
      ${media.desktop`
         &:last-of-type {
           margin: 0;
         }
      `}
    `}
`;

type ResultTotalConsumerContainerProps = {
  $isConsumerCarsTable: boolean;
};
